.size__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.size__item {
  display: grid;
  grid-template-columns: 50% 1fr 1fr;
  gap: 4px;
  align-items: center;
}

.size__desc {
  grid-column: 1/2;
  margin: 0;
  font-size: calc(.3rem + .7vw);
  font-weight: 300;
  line-height: 1.2;
  color: rgba(133, 133, 133, 1)
}

.price__input_min {
  grid-column: 2/3;
}

.price__input_max {
  grid-column: 3/-1;
}
