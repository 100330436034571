.left-menu{
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media screen and (max-width: 961px) {
  .left-menu {
    display: none;
  }
}
