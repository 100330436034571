.list-checkboxes{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
}

.list-checkboxes__name{
  margin: 0 6px;
}
