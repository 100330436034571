.custom-accordion-category{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 10px;
  background-color: #E1C493A1;
}

.custom-accordion-category__body{
  text-decoration: none;
  color: black;
  font-size: 16px;
  padding: 0;
}

.custom-accordion-category__link{
  text-decoration: none;
  color: black;
  font-size: 16px;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
}

.custom-accordion-category__item{
  min-height: 50px;
  padding: 15px;
  font-size: 14px;
  /*border-bottom: 1px solid black;*/
}

.custom-accordion-category__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.custom-accordion-category__collapse{
  border: none;
  background-color: inherit;
}

.custom-accordion-category__collapse-image{
  transform: rotate(0);
  transition: transform .3s ease;
}

.custom-accordion-category__collapse-image_active{
  transform: rotate(180deg);
}

.custom-accordion-category__body{
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;
  width: 100%;
}

.custom-accordion-category__body_active{
  max-height: 1000px;
}
