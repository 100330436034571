.products{
  margin-top: 30px;
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fill, 233px);
  justify-content: center;
}

.hero{
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.page__link{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: #000;
  transition: box-shadow .4s ease;
  padding: 7px 5px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.11);
}

.page__image{
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

@media screen and (max-width: 543px) {
  .products {
    grid-template-columns: none;
    justify-content: none;
  }
}
