.modal-dialog 
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  margin: 0 auto

.modal-content 
  width: 90%
  max-width: 500px
  justify-content: center
  margin: 20px auto
  padding: 20px

// .form-control
//   margin-bottom: 20px


