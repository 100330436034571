@import "../../../../constants/colors"

/* Таблица характеристики */
.table-specs
  float: left
  width: 46%
  margin: 20px 2%
  display: table
  border-collapse: separate
  box-sizing: border-box
  text-indent: initial
  border-spacing: 2px
  border-color: gray

.table-specs__head 
  font-size: 14px
  color: #000000
  text-align: left
  text-transform: uppercase
  font-weight: 700

table-specs__title, table-specs__text
  font-family: 'PT Sans', sans-serif
  font-size: 14px
  color: #333333
  font-weight: 400

$table_row_border_color: lightgray

.table-specs__title
  font-size: 14px
  color: #000000
  text-align: left
  line-height: 21px
  border-bottom: 1px solid $table_row_border_color

.table-specs__text
  font-size: 14px
  color: #757575
  text-align: right
  line-height: 21px
  border-bottom: 1px solid $table_row_border_color

.table-specs__text a
  color: $orange-color

@media screen and (max-width: 991px)
  .table-specs
    float: none
    width: 100%
    margin: 20px 0