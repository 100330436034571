.doors-category__wrapper{
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: max-content max-content;
  gap: 30px;
}

.doors-category__go-back{
  grid-row: 1/2;
  grid-column: 1/-1;
  background-color: #ffc107;
  width: 400px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  border: none;
}

.products__category1{
  grid-row: 2/-1;
  grid-column: 1/-1;
}

.custom-accordion__wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(187, 203, 187, 0.5);
  grid-row: 2/-1;
  grid-column: 1/2;
  width: 100%;
}

.doors__products{
  padding: 7px;
  margin: 0;
  width: 100%;
  grid-row: 2/-1;
  grid-column: 2/-1;
}

.custom-accordion__list{
  padding: 7px;
  margin: 0;
  width: 100%;
  grid-row: 2/-1;
  grid-column: 1/2;
}

.custom-accordion__item{
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

@media screen and (max-width: 543px) {
  .doors-category__go-back{
    width: 270px;
    max-width: 100%;
  }
}
