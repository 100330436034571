.preview__door{
  overflow: hidden;
  width: 241px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
  transition: all .4s ease;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  position: relative;
}

.preview__door:hover{
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all .5s;
}
