.doors-category__wrapper{
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: max-content max-content;
  gap: 30px;
}

.doors-category__go-back1{
  grid-row: 1/2;
  grid-column: 1/-1;
  background-color: #ffc107;
}

.custom-accordion__wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(187, 203, 187, 0.5);
  grid-row: 2/-1;
  grid-column: 1/2;
  width: 100%;
}

.doors__products{
  padding: 7px;
  margin: 0;
  width: 100%;
  grid-row: 2/-1;
  grid-column: 2/-1;
}

.custom-accordion__list{
  padding: 7px;
  margin: 0;
  width: 100%;
  grid-row: 1/-1;
  grid-column: 1/2;
}

.custom-accordion__item{
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.button__wrapper-hero{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
