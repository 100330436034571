.products__category{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
}

.products__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 18px;
  /*grid-template-columns: repeat(auto-fill, 233px);*/
  /*grid-template-rows: repeat(auto-fill, max-content);*/
  /*justify-content: center;*/
}

.btn-collection {
  width: 300px;
  align-self: center;
  color: rgb(255, 255, 255) !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 543px) {
  .products__list {
    grid-template-columns: none;
    justify-content: none;
  }
}
