.feedback__title {
  padding: 15px 0;
  font-size: 22px;
  font-weight: 700;
}

.feedback__form {
  display: flex;
  flex-direction: column;
}

.feedback__form input {
  width: 385px;
  height: 55px;
  margin-bottom: 18px;
  border-radius: 4px;
  border: 2px solid #cfcfcf;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 18px;
}

.feedback__form textarea {
  width: 385px;
  height: 122;
  margin-bottom: 18px;
  border-radius: 4px;
  border: 2px solid #cfcfcf;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 18px;
}

.feedback-form__button {
  width: 385px;
}/*# sourceMappingURL=FeedbackForm.css.map */