.left-menu_filter{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 300px;
  box-sizing: border-box;
  font-size: calc(.3rem + .7vw);
  font-weight: 300;
  line-height: 1.2;
  color: rgba(133, 133, 133, 1);
  padding-right: 30px;
  margin-top: 10px;
}



.confirm-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.nav-link{
  color: rgba(133, 133, 133, 1) !important;
}

.confirm-btn{
  width: 50%;
  font-size: calc(.3rem + .7vw) !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
}

.accordion-button{
  font-size: calc(.3rem + .7vw) !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
}

.btn-active{
  background-color: rgba(255,193,7,0.35) !important;
  box-shadow: 0 4px 7px rgba(255,193,7,0.35);
}

.nav-toggle{
  width: 300px;
}

@media screen and (max-width: 991px) {
  .left-menu_filter, .nav-toggle {
    display: none !important;
  }
}
