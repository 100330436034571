.contacts__info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 2px solid #b4b4b4;
  border-radius: 10px;
  padding: 0 15px;
  min-height: 142px;
  overflow: hidden;
  position: relative;
}

.contacts__phone,
.contacts__email,
.contacts__schedule {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 26%;
  padding: 26px 0 26px 10px;
  box-sizing: border-box;
  overflow: visible;
  margin: 0;
}

.contacts-span {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  margin: 0 0 5px;
  color: #919191;
}

.contacts__content {
  font-size: 15px;
  font-weight: 700;
}

.phone__wrapper {
  display: flex;
  flex-direction: row;
}

.contacts__icon {
  margin: 22px 19px 0 18px;
}

.contacts__action-wrapper {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contacts__address {
  position: relative;
  z-index: 100000000;
  background-color: white;
  padding: 10px 20px;
  width: 200px;
  border-radius: 10px;
  top: 200px;
  left: 200px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  font-size: 14px;
  font-weight: 700;
}

.contacts__social {
  position: absolute;
  right: 12px;
  top: 150px;
  display: flex;
}

.contacts__social-link {
  margin-right: 10px;
}

.contacts__map {
  width: 600px;
  height: 400px;
}

@media screen and (max-width: 1377px) {
  .contacts__img {
    display: none;
  }
  .contacts__phone,
  .contacts__email,
  .contacts__schedule {
    max-width: 33%;
    padding: 26px 0;
  }
  .contacts__social {
    top: 70%;
  }
  .contacts__action-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .contacts__map {
    margin-top: 30px;
  }
}
@media screen and (max-width: 991px) {
  .contacts__info {
    flex-direction: column;
    border: none;
  }
  .contacts__phone,
  .contacts__email,
  .contacts__schedule {
    max-width: 100%;
    width: inherit;
    padding: 20px 0;
  }
  .contacts__social {
    top: 89%;
  }
}
@media screen and (max-width: 600px) {
  .contacts__map {
    width: 400px;
    height: 200px;
  }
}/*# sourceMappingURL=ContactsPage.css.map */