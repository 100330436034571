@import url(./vendor/normalize.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#root{
  /* background-color: rgba(201, 190, 190, 0.42); */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main{
  grid-row: 2/3;
  grid-column: 1/-1;
  width: 100%;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr;
}

.no-list{
  list-style-type: none;
  padding-left: 0;
}

.input{
  border-bottom: 1px solid rgba(255,193,7,0.75);
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: calc(.7rem + .7vw);
  font-weight: 300;
  line-height: 1.2;
  color: rgba(133, 133, 133, 1)
}

.input:focus{
  outline: none;
}

.ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Необходимость, так как навбара на мобиле не будет */
@media screen and (max-width: 961px) {
  .main {
    display: block;
  }
}


