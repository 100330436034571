.calculator_quanity
  padding: 7px
  font-size: 14px

.calculator_quanity-present
  color: #388E3C
  position: relative
  &::before
    content: ""
    display: block
    position: absolute
    height: 7px
    width: 7px
    background-color: #388E3C
    border-radius: 50%
    left: -10px
    top: 5px

.calculator_quanity-absent
  color: #f44336
  position: relative
  &::before
    content: ""
    display: block
    position: absolute
    height: 7px
    width: 7px
    background-color: #f44336
    border-radius: 50%
    left: -13px
    top: 5px