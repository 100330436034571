.preloader {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 20px 0;
  /*position: absolute;*/
}

.preloader__container {
  position: relative;
}

.preloader__round {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #a7bbcc;
  border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
}

.preloader__round::after {
  background: #ffc107;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
  z-index: 10;
  background: #a7bbcc;
  animation: preloader-inside-red 1s ease-in-out infinite;
}


.preloader__image {
  width: 100px;
  animation: spin 2s linear infinite;
}

@keyframes scaleAndGlow {
  0% {
    transform: scale(1);
    fill: rgba(56, 173, 159, 0.58); /* начальный цвет */
    filter: drop-shadow(0 0 5px rgba(200, 218, 62, 0.63)); /* добавление свечения */
  }
  50% {
    transform: scale(1.3); /* увеличение в 1.2 раза */
    fill: rgba(31, 29, 29, 0.79); /* изменение цвета */
    filter: drop-shadow(0 0 10px rgba(161, 81, 81, 0.63)); /* увеличение свечения */
  }
  100% {
    transform: scale(1);
    fill: rgba(56, 173, 159, 0.58); /* начальный цвет */
    filter: drop-shadow(0 0 5px rgba(200, 218, 62, 0.63)); /* добавление свечения */
  }
}

.preloader__image {
  animation: scaleAndGlow 2s ease-in-out infinite; /* применение анимации к элементу с классом cls-3 */
}

.cls-1 {
  fill: rgba(245, 2, 2, 0.6); /* Устанавливаем цвет заливки */
  stroke: #00940d; /* Устанавливаем цвет обводки */
  stroke-width: 8.5px; /* Устанавливаем толщину обводки */
}
