.gallery-photos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__wrapper{
  width: 400px;
  box-sizing: border-box;
}

.card__wrapper{
  width: 80vw;
}

.gallery__prev-wrapper{
  padding: 0;
  margin: 0 12px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 10px 2px rgba(103, 102, 98, 0.5);
  background-color: transparent;
  transform: scale(1);
  transition: all .3s ease;
}

.gallery__prev-wrapper_active{
  background-color: rgba(255, 255, 255, 0.53);
}

.gallery__prev-wrapper:hover, .gallery__prev-wrapper_active:hover{
  cursor: pointer;
  transform: scale(1.3);
}

.gallery__next{
  transform: rotate(180deg);
}

.product-slider{
  width: 400px;
  height: 400px;
}

.product-slider_active{
  width: 80vw;
  height: 80vh;
}

.slick-prev{
  left: -55px !important;
}

.slick-next{
  right: -55px !important;
}

.slick-prev, .slick-next{
  /*color: #0a53be !important;*/
  width: 40px !important;
  height: 40px !important;
  background-color: #0a53be !important;
}
.gallery__card{
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.gallery__card:hover{
  cursor: pointer;
}

.gallery__card_active{
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
}


.gallery-photos__slider {
  max-width: 390px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 3px solid #ffc107 !important;
}

.image-gallery-thumbnail:hover {
  border: 3px solid #ffc107 !important;
}

.image-gallery-fullscreen-button:hover {
  color: #ffc107 !important;
}

.image-gallery-left-nav:hover,
.image-gallery-right-nav:hover {
  color: #ffc107 !important;
}

.fullscreen .image-gallery-slides div img {
  height: 100%;
}/*# sourceMappingURL=GalleryCards.css.map */

.gallery-photos_active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.fullscreen__close{
  display: flex;
  justify-self: center;
  align-items: center;
  border: none;
  /*padding: 0;*/
  border-radius: 50%;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  /*background-color: white;*/
  box-shadow: 0 0 10px 2px rgba(103, 102, 98, 0.5);
  background-color: rgba(255, 255, 255, 0.53);;
  transform: scale(1);
  transition: all .3s ease;
}

.fullscreen__close-image{
  width: 20px;
}

.fullscreen__close:hover{
  cursor: pointer;
  transform: scale(1.3);
}

.gallery-photos_active .gallery__card {
  max-width: 100%;
  max-height: 100%;
}



@media all and (max-width: 768px){
  .gallery__prev-wrapper{
    display: none;
    color: rgba(239, 231, 148, 0.78);
  }
  .fullscreen__close{
    right: 15px;
    top: 15px;
  }
}

@media all and (max-width: 400px){
  .product-slider{
    width: 100%;
    max-height: 400px;
  }
}


