.product-specs {
  margin-top: 20px;
  grid-row: 2/-1;
  grid-column: 1/-1;
}

.product-specs__title {
  position: relative;
  padding-left: 7px;
  font-size: 24px;
  margin-right: 15px;
  padding-right: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-item > .active {
  border-bottom: 2px solid #fea305;
  transition: all 0.1s ease;
}

.nav-item > .active .product-info__title {
  color: black;
}

.info-icon {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.info-link {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

@media screen and (max-width: 450px) {
  .product-specs__title {
    position: relative;
    padding-left: 7px;
    font-size: 11px;
    margin-right: 0;
    padding-right: 0;
  }
}/*# sourceMappingURL=SpecsTabs.css.map */