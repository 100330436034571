.pagination {
  background-color: inherit;
  color: #ffc107;
  margin: 20px auto !important;
  max-width: max-content !important;
  display: flex !important;
  align-items: stretch !important;
}

.pagination a {
  color: black;
  background-color: rgba(255, 193, 7, 0.6) !important;

}

.active>.page-link, .page-link.active{
  background-color: #0f5132 !important;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.pagination .disabled a {
  pointer-events: none;
  background-color: #0f5132 !important;
}
