.parent-category__link{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 200px;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: #000;
  transition: box-shadow .4s ease;
  padding: 7px 5px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.11);
}

.parent-category__link:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.parent-category__image{
  object-fit: contain;
}

.parent-category__name{
  padding: 0;
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 991px) {
  .parent-category__link{
    width: 80%;
    border: 1px solid wheat
  }

}
