.category__link{
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  box-shadow: none;
  transition: box-shadow .4s ease;
  padding: 7px 5px;
  max-width: 90%;
  border-radius: 10px;
}

.category__link:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.category__image{
  width: 35px;
  height: 35px;
}

.category__name{
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.category__name_type_footer{
  font-size: 14px;
}
