.product {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: repeat(2, max-content);
  -moz-column-gap: 20px;
       column-gap: 20px;
}

.product__display {
  grid-row: 1/2;
  grid-column: 1/2;
}

.product__title {
  text-transform: uppercase;
  font-size: 20px;
  color: #121212;
  text-align: left;
  font-weight: 700;
}

.product__code {
  font-size: 17px;
  color: #121212;
  text-align: left;
  font-weight: 500;
}

/*price*/
.price {
  grid-row: 1/2;
  grid-column: 2/-1;
  margin-top: 70px;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  height: 400px;
  min-width: 200px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
}

.product-info__name {
  font-size: 14px;
  color: #000;
}

.product-info__name_type_val {
  font-weight: 700;
}

/*calculator*/
.calculator {
  display: flex;
  flex-direction: column;
}

.calculator__price {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.calculator__regular-price {
  font-size: 24px;
  color: gray;
  text-decoration: line-through;
  text-decoration-color: #f44336;
  text-align: left;
  font-weight: 700;
}

.calculator__special-price {
  font-size: 36px;
  color: #000;
  text-align: left;
  font-weight: 700;
}

.calculator__form {
  display: flex;
  gap: 12px;
}

.calculator__count {
  max-width: 80px;
  border: 1px solid #000;
  border-radius: 12px;
  background: transparent;
  padding: 7px;
  font-size: 17px;
  font-weight: 600;
}

.calculator__submit {
  border-radius: 12px;
}

.description {
  grid-row: 3/-1;
  grid-column: 1/-1;
  margin-top: 24px;
  width: 100%;
  border-top: 2px solid #fea305;
  padding: 15px 2%;
  margin-bottom: 30px;
}

.description p {
  margin: 0;
}

.description h4 {
  text-transform: uppercase;
}

.series__products {
  grid-column: 1/-1;
  padding: 0 2%;
  width: 100%;
}

.series__products-title {
  margin-bottom: 35px;
}

@media screen and (max-width: 991px) {
  .product {
    display: block;
  }
  .price {
    height: -moz-max-content;
    height: max-content;
    margin-top: 25px;
  }
}/*# sourceMappingURL=Product.css.map */