.checkbox-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 300px;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
}

.checkbox__name{
  margin: 0;
}

.checkbox__checkmark {
  position: relative;
  width: 20px;
  height: 20px;
  /*background-color: #ffc107;*/
  background-color: #fff;
  border: 1px solid #ffc107;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
}

.checkbox__checkmark:hover{
  cursor: pointer;
}

.checkbox-wrapper:hover{
  cursor: pointer;
}

.checkbox__checkmark::before {
  content: "✔️";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: black;
  opacity: 0;
}

.checkbox__input:checked + .checkbox__checkmark::before {
  opacity: 1;
}

.checkbox__input:checked + .checkbox__checkmark{
  background-color: #ffc107;
}
