.basket__title {
  padding: 15px 0;
  font-size: 24px;
  font-weight: 700;
}

.fixed-bottom {
  height: 40px;
}

.bg-white {
  height: 90px;
  display: flex;
  align-items: center;
}/*# sourceMappingURL=BasketPage.css.map */