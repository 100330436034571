.price__wrapper{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  box-sizing: border-box;
  max-width: 100%;
}

.price__input{
  grid-column: 2/-1;
  width: 100%;

}

.price__desc{
  margin: 0;
  grid-column: 1/2;
  font-size: calc(.3rem + .7vw);
  font-weight: 300;
  line-height: 1.2;
  color: rgba(133, 133, 133, 1)
}

