.gallery-photos
  display: flex
  justify-content: center



.gallery-photos__slider
  max-width: 390px

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus
  border: 3px solid #ffc107 !important

.image-gallery-thumbnail
  &:hover
    border: 3px solid #ffc107 !important

.image-gallery-fullscreen-button
  &:hover
    color: #ffc107 !important

.image-gallery-left-nav,
.image-gallery-right-nav
  &:hover
    color: #ffc107 !important

.fullscreen .image-gallery-slides div img
  height: 100%





