.left-menu_filter-mobile{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  color: rgba(133, 133, 133, 1);
  padding-right: 15px;
  margin-top: 200px;
}
