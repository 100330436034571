.content{
  display: grid;
  grid-template-columns: 20% 1fr 20%;
  grid-template-rows: max-content 1fr max-content;
  min-height: calc(100vh - 107px);
  max-width: 1440px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 30px auto 30px auto;
}

.page__title {
  padding: 15px 0;
  font-size: 28px;
  font-weight: 700;
}