.product-door__container{
  padding: 15px;
  background-color: rgba(187, 123, 123, 0.35);
  width: 100%;
  grid-row: 1/-1;
  grid-column: 1/-1;
  display: grid;
  min-height: 400px;
  grid-template-rows: repeat(4, minmax(50px, max-content));
  grid-template-columns: 60% 40%;
  border-radius: 12px;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  border: none;
}

.product-door__title{
  font-size: 20px;
  grid-row: 1/2;
  grid-column: 1/-1;
  text-align: center;
  color: black;
}

.product-door__information{
  grid-row: 2/3;
  grid-column: 2/-1;
  padding: 15px;
}

.colors{
  list-style-type: none;
  padding: 0;
}

.product-door__image{
  grid-row: 2/3;
  grid-column: 1/2;
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.door-color{
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px
}

.door-color__item:hover{
  cursor: pointer;
}

.door-color__image{
  width: 40px;
}

.door-size{
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px
}

.door-size__item{
  background-color: rgba(109, 137, 178, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.door-size__item_active{
  border-bottom: 1px solid black;
}

.door-size__item:hover{
  cursor: pointer;
}

.door-size__description{
  margin: 0;
  text-align: center;
}

.characteristics-door{
  list-style-type: none;
  padding: 0;
}

.characteristics-door__value{
  padding: 0;
}

.characteristics-door__name{
  font-weight: bold;
}

.complect{
  grid-row: 3/-2;
  grid-column: 1/-1;
  padding: 10px;
  border: 1px solid black;
  margin-top: 20px;
}

.product-door__go-back{
  grid-column: 1/-1;
  grid-row: -2/-1;
  background-color: #ffc107;
  border-radius: 12px;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  border: none;
  width: 400px;
  margin: 0 auto;
}

.complect__title{
  text-align: center;
  padding: 20px;
  font-size: 0.933em;
  line-height: 1.501em;
}

.complect__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.door-head-product{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.door-head-product__image{
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.door-add-product{
  width: 50%;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.door-add-product__item{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.door-add-product__image{
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.door-add-product__title{
  font-size: 14px;
  max-width: 50%;
  padding: 0;
  margin: 0;
}

.door-head-product__title{
  font-size: 14px;
}

.door-product__total-price{
  width: 50%;
  padding: 20px;
  border-left: 1px solid black;
}

.product-door__preloader{
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.calculator__submit{
  border-radius: 12px;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  border: none;
}


@media screen and (max-width: 543px) {
  .product-door__go-back{
    width: 270px;
    max-width: 100%;
  }
  .product-door__image {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    width: 100%;
    object-fit: contain;
  }
  .product-door__information {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    padding: 15px;
  }
  .complect {
    grid-row: 4 / -1;
    grid-column: 1 / -1;
    border: none;
  }
  .complect__title{
    padding: 0;
  }
  .complect__wrapper {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .door-add-product {
    width: 100%;
  }
  .door-add-product__item{
    flex-wrap: wrap;
  }
  .door-add-product__title {
    max-width: max-content;
    width: 70%;
  }
  .door-add-product__quantity{
    max-width: 100%;
    width: max-content;
    margin: 0 auto;
  }
  .door-product__total-price {
    width: 100%;
    padding: 20px;
    border: none;
  }
  .calculator__submit{
    background-color: rgba(255, 255, 255, 0.31);
  }
  .door-product__total-title{
    text-align: center;
  }

}
