.manufacturers-title{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.manufacturers-title__logo{
  height: 40px;
  margin-right: 20px;
}

.empty-page{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-page__link{
  color: #ffc107;
}