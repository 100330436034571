.no-result{
  grid-row: 1/-1;
  grid-column: 2/-1;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}

.no-result__title{
  text-align: center;
  color: rgb(133, 133, 133);
  margin: 20px auto;
}
