.pagination__item{
  color: #000 !important;
  font-weight: 500;
  background-color: #ffc107 !important;
  border-radius: 12px !important;

}

.pagination__num{
  color: #000 !important;
  font-weight: 500;
  font-size: 23px;
  padding: 3px 10px 0 10px;
  margin: 0;
}

.pagination__wrapper{
  display: flex !important;
  justify-content: center !important;
}
