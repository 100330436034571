.preview-wrapper{
  height: max-content;
  text-decoration: none;
}

.preview-wrapper:hover{
  text-decoration: none;
}

.preview{
  overflow: hidden;
  width: 241px;
  height: 444px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
  transition: all .4s ease;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  position: relative;
}

.preview:hover{
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all .5s;
}

.preview__name{
  overflow: hidden;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  font-weight: 700;
  margin: 14px 0 10px 0;
  text-align: center;
  height: 45px;
}

.discount {
  position: absolute;
  right: 5px;
  color: white;
  background-color: #f44336;
  padding: 3px;
  border-radius: 10px;
}

.preview__image{
  width: 100%;
  height: 205px;
  object-fit: contain;
}

.preview__desc-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.preview__article{
  color: #666666;
  font-size: 14px;
  margin: 0;
}

.preview__to{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview__price{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.regular__price{
  font-size: 16px;
  color: gray;
  text-decoration: line-through;
  text-decoration-color: #f44336;
  text-align: left;
  font-weight: 700;
  /* line-height: 40px; */

}

.special__price{
  font-size: 16px;
  color: black;
  text-align: left;
  font-weight: 700;
  /* line-height: 40px; */
}

@media screen and (max-width: 543px) {
  .preview {
    /*box-shadow: none;*/
    width: 100%;
  }
}
