.vendorsPage{
  padding-left: 20px;
}

.products__title{
  padding: 0;
}

.title {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.manufacturer-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.vendors-navigation {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
  border-bottom: 2px solid #ffc107;
}

.letters-column {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.letters-column div {
  font-weight: 700;
  cursor: pointer;
}

.search-bar input {
  width: 250px;
}

.manufacturer-group {
  width: 100%;
}

h2 {
  width: 100%;
}

.manufacturer-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 20px 0;
}
@media (max-width: 768px) {
  .manufacturer-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.manufacturer-item {
  color: #919191;
  cursor: pointer;
}

@media screen and (max-width: 1340px) {
  .vendors-navigation {
    flex-direction: column;
  }
  .search-bar {
    margin-top: 20px;
  }
}/*# sourceMappingURL=VendorsPage.css.map */
