.menu__item__text {
  font-size: 20px;
  /* margin-right: 10px; */
  margin-left: 5px;
}

.navbar-down {
  height: 60px;
}

.navbar-link {
  display: flex;
  align-items: center;
}

.cart {
  display: flex;
  padding: 0 40px;
}

.cart-icon {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
}

.cart-count {
  background: #FFC107;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-left: -15px;
}

.cart-mobile {
  display: none;
  height: 50px;
}

.navbar-mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .cart {
    display: none;
  }

  .cart-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-icon {
    flex-direction: row;
    justify-content: center;
  }

  .menu__item__image {
    margin-right: 15px;
  }

  .cart-count {
    background: #FFC107;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin-left: 10px;
    margin-top: 2px;
  }

  .navbar-mobile {
    margin-top: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #FFC107;
    border-bottom: 2px solid #FFC107;
  }

  .navbar__mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px
  }

}
