.preview-collection{
  overflow: hidden;
  width: 233px;
  padding: 15px;
  background-color: #fff;
  border-radius: 12px;
  transition: all .4s ease;
  box-shadow: 3px 9px 16px -3px rgba(34, 60, 80, 0.2);
  position: relative;
}

.preview-collection:hover{
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all .5s;
}

.preview-collection__name{
  overflow: hidden;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding-top: 15px;
}

.preview-collection__image{
  width: 100%;
  height: 205px;
  object-fit: cover;
}